import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import './Cards.css'

const useStyles = makeStyles({
    root: {
        maxWidth: "266",
        width: "100%",
        fontWeight: "bold"
    },
    media: {
        height: 200,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
    }
})

export const Cards = (props: { details: any[] }) => {
    const classes = useStyles();

    const renderCard = (detail: any, index: any) => {
        return (
            <Grid
                container
                direction='row'
                item
                xs={10}
                sm={5}
                md={3}
                lg={2}
                xl={1}
                alignItems='center'
                justify='center'
                key={index}
            >
                <Card
                    className={classes.root}
                    key={detail.title}
                    style={{ margin: '6px' }}
                >
                        <CardMedia
                            data-card-media={detail.title}
                            className={classes.media}
                            image={
                                detail.image
                                    ? detail.image
                                    : '100ft-robot-golf.webp'
                            }
                            title={detail.title}
                        />
                        <CardContent>
                            <Typography className='gamename' variant='body2' component='p' style={
                                { fontSize: 16, overflowX: "hidden", fontWeight: "bold"}
                                }>
                                {detail.description}
                            </Typography>
                        </CardContent>
                        {/* {detail.renderFooter} */}
                </Card>
            </Grid>
        )
    }

    return (
        <Grid container justify='center'>
            {props.details.map((detail, index) => renderCard(detail, index))}
        </Grid>
    )
}
