import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Tooltip from "@material-ui/core/Tooltip";
import Slider from "@material-ui/core/Slider";
import { printIntrospectionSchema } from 'graphql';
import './ScoreFilter.css'
import { BlockRounded } from '@material-ui/icons';


const ColorButton = withStyles((theme: Theme) => ({
    root: {
        color: "#fff",
        backgroundColor: "#209cee",
        '&:hover': {
            backgroundColor: "#209cee",
        }
    },
}))(Button);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        typography: {
            padding: theme.spacing(2),

        },
    }),
);

const useSliderStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {

        },
        margin: {
            height: theme.spacing(3)
        }
    })
);

export function ScoreFilter(props: any) {
    const [buttonClass, setButtonClass] = useState("");


    // ======== This is boilerplate code for PopOver =======
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "score-filter" : undefined;

    // ======================================================

    // ============== Styling code for slider ===============
    const sliderClasses = useSliderStyles();

    const IOSSlider = withStyles({
        root: {
            color: "#000",
            height: 6,
            fontFamily: `'Courier New', Courier, monospace`,
            width: "90%",
            textAlign: "center",
            fontWeight: "bold",
        },
        thumb: {
            height: 20,
            width: 5,
            backgroundColor: "#000",
            marginTop: -8,
            borderRadius: "0%",
            marginLeft: 0
        },
        active: {},
        valueLabel: {
            top: -22,
            "& *": {
                background: "transparent",
                color: "#000"
            }
        },
        track: {
            height: 6
        },
        rail: {
            height: 6,
            color: "currentColor",
        }
    })(Slider);

    // ======================================================

    // Used to preserve previous filters
    const [score, setScore] = useState([0, 100]);

    function handleScoreChange(event: React.ChangeEvent<{}>, val: number | number[]) {

        if (typeof val !== "number") {
            setScore(val);
        }
    }

    function valuetext(value: number) {
        return `${value}%`;
    }

    const handleResetClick = () => {
        setScore([0, 100]);
    };

    useEffect(() => {
        if (!(score[0] == 0 && score[1] == 100)) {
            setButtonClass("is-primary-button");
        } else {
            setButtonClass("");
        }
        props.scoreChangeHandler(score);
    }, [score])

    return (
        <div style={{ marginLeft: "5px", marginTop: "2px", display: "inline-block" }}>
            <button type="button" className={buttonClass} onClick={handleClick}>Rating</button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div className={classes.typography} style={{ height: "100%", width: "320px", textAlign: "center", fontSize: "12px"}}>
                    <button type="button" className="is-error-button" style={{position: "absolute", top: 5, right: 5}} onClick={handleResetClick}>Reset</button>
                    <div className="pure-g" style={{paddingBottom: 5}}>
                        <div className="pure-u-1-2" style={{textAlign: "left"}}><h3>Aggregated Critic Score</h3></div>
                    </div>
                    <div className="pure-g">
                        <div className="pure-u-1-12 score-label">{Math.min.apply(Math, score)}</div>
                        <div className="pure-u-3-4">
                            <div className={sliderClasses.root}>
                                <IOSSlider
                                    aria-labelledby="score-track-slider"
                                    getAriaValueText={valuetext}
                                    defaultValue={score}
                                    valueLabelDisplay="auto"
                                    min={0}
                                    max={100}
                                    onChangeCommitted={handleScoreChange}
                                />
                            </div>
                        </div>
                        <div className="pure-u-1-12 score-label">{Math.max.apply(Math, score)}</div>
                    </div>

                </div>
            </Popover>
        </div>
    );
}
