import { inherits } from 'util';
import './FilterBar.css'
import { GenreFilter } from "./GenreFilter";
import { ScoreFilter } from "./ScoreFilter";
import { YearFilter } from "./YearFilter";
import { SubscriptionFilter } from './SubscriptionFilter';
// import {SubscriptionFilter} from "./SubscriptionFilter";

export function FilterBar(props: any) {

    return (
        <div>
            <div className="pure-g" style={{ fontSize: 16, fontWeight: 'bold', fontFamily: "'Courier New', Courier, monospace" }}>
                <div className="pure-u-2-3 filter">
                    <img alt="Filters" title="Filters" src="https://cdn3.iconfinder.com/data/icons/google-material-design-icons/48/ic_filter_list_48px-512.png" width="24" height="24" style={{ marginLeft: "5px", marginTop: "2px", display: "inline-block" }} />
                    {/* <SubscriptionFilter subscriptions={props.subscriptions} /> */}
                    {/* Filters: */}
                    <SubscriptionFilter subscriptions={props.subscriptions} subscriptionChangeHandler={props.subscriptionChangeHandler}/>
                    {/* <GenreFilter genres={props.genres} /> */}
                    <ScoreFilter scoreChangeHandler={props.scoreChangeHandler} />
                    {/* <YearFilter yearChangeHandler={props.yearChangeHandler} /> */}
                </div>
                <div className="pure-u-1-3 filter">
                    <div className="nes-field is-inline" style={{ float: 'right' }}>
                        {props.games_count} Games&nbsp;
                        <div className="filter-bar">
                            <select required id="default_select" onChange={props.orderChangeHandler} style={{fontWeight: "bold"}}>
                                <option value="0">A to Z</option>
                                <option value="1">Score</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}