import React from 'react';
import { GamesContainer } from './GamesContainer'
import './App.css';

interface AppProps {
}

interface AppState {
  isGalleryActive: boolean
}

class App extends React.Component<AppProps, AppState>{

  render() {
    return (
      <div className="app">
        <div className="containerpure-menu pure-menu-horizontal pure-menu-scrollable">
          <a href="#" className="pure-menu-heading"><i className="snes-jp-logo brand-logo "></i></a>
          <a href="#" className="pure-menu-heading pure-menu-link "><span className="nes-text is-primary">Stack Up</span></a>
          <ul className="pure-menu-list">
            <li className="pure-menu-item">
              <a href="#" className="pure-menu-link">About</a>
            </li>
            <li className="pure-menu-item">
              <a href="#" className="pure-menu-link">Help</a>
            </li>
          </ul>
        </div >
        <div className="nes-container content">
          <div className="container-items">
            <h2>Howdy!</h2>
            <div className="lists">
              <ul className="nes-list">
                <li>* Compare <span style={{ color: "#107C10" }}>Xbox Game Pass</span>, <span style={{ color: "#003791" }}>PS Now</span> and other game subscription service catalogs.</li>
                <li>* Already own one or more subscriptions? <span className="nes-text is-error">Filter</span> & <span className="nes-text is-error">sort</span> games by ratings, release data and genre to figure out what to play next!</li>
              </ul>
            </div>
            
          </div>
        </div>
      <GamesContainer/>
      </div>
    );
  }
}

export default App;