import InfiniteScroll from 'react-infinite-scroll-component';
import { Cards } from './Cards';
import { FilterBar } from './FilterBar';
import { query } from './Database';
import { useState } from 'react';

function gamesToDetails(games: any) {
    var newDetails = new Array();
    games.forEach((element: any) => {
        var newElement = {
            description: element[1],
            image: element[4],
            renderFooter: <div style={{ fontSize: 12 }}><a className="nes-badge"><span className="is-primary">sports</span></a></div>
        }
        newDetails.push(newElement);
    });
    return newDetails;
}

export function GamesContainer(): JSX.Element {
    const [limit, setLimit] = useState(40);
    const [offset, setOffset] = useState(0);

    const [order, setOrder] = useState("0");
    const [needsRender, setNeedsRender] = useState(true);

    function orderChangeHandler(e: any) {
        setOffset(0);
        setOrder(e.target.value);
    }

    const [filters, setFilters] = useState({
        score: [0, 100],
        year: [1960, 2021],
        subs: {
            'PS Now': {
                id: 0,
                checked: true,
            },
            'Xbox Game Pass': {
                id: 1,
                checked: true,
            },
            'Xbox Game Pass PC': {
                id: 2,
                checked: true,
            },
        },
        genre: {
            Action: {
                checked: true
            },
            Adventure: {
                checked: true
            },
            Arcade: {
                checked: true
            },
            Fighting: {
                checked: true
            },
            FPS: {
                checked: true
            },
            Horror: {
                checked: true
            },
            MMO: {
                checked: true
            },
            MOBA: {
                checked: true
            },
            Misc: {
                checked: true
            },
            Party: {
                checked: true
            },
            Platformer: {
                checked: true
            },
            Puzzle: {
                checked: true
            },
            Racing: {
                checked: true
            },
            RPG: {
                checked: true
            },
            RTS: {
                checked: true
            },
            Rhythm: {
                checked: true
            },
            Roguelike: {
                checked: true
            },
            Sandbox: {
                checked: true
            },
            Simulation: {
                checked: true
            },
            Sports: {
                checked: true
            },
            Survival: {
                checked: true
            },
            "Turn Based": {
                checked: true
            }

        }
    });

    const subscriptionChangeHandler = (subscriptionName: string | null) => {
        setOffset(0);
        if (subscriptionName) {
            let newSubs: any = filters.subs;
            newSubs[subscriptionName].checked = !newSubs[subscriptionName].checked;
            setFilters(filters => ({ ...filters, subs: newSubs }));
        }
        else {
            setNeedsRender(!needsRender);
        }
    };

    function scoreChangeHandler(score: number[]) {
        setOffset(0);
        setFilters(filters => ({ ...filters, score: score }));
    }

    function yearChangeHandler(year: number[]) {
        setOffset(0);
        setFilters(filters => ({ ...filters, year: year }));
    }

    const fetchSubs = () => {
        let subs: number[] = [];
        for (const [key, value] of Object.entries(filters.subs)) {
            if (value.checked) {
                subs.push(value.id);
            }
        }
        return subs;
    }

    // Get games using filter
    const data = query({
        order_by: order,
        score_lo: Math.min.apply(Math, filters.score),
        score_hi: Math.max.apply(Math, filters.score),
        subs: fetchSubs(),
    })
    const games = gamesToDetails(data);

    function fetchNextData(): void {
        setOffset(offset + limit);
    }

    return (
        <div className="nes-container content games-container">
            {/* <SubscriptionBar /> */}
            <FilterBar
                scoreChangeHandler={scoreChangeHandler}
                orderChangeHandler={orderChangeHandler}
                // yearChangeHandler={yearChangeHandler}
                subscriptionChangeHandler={subscriptionChangeHandler}
                genres={Object.keys(filters.genre)}
                subscriptions={filters.subs}
                // If games_count is still loading show a default value of 0, otherwise
                // react complaints about undefined values
                games_count={data.length} />
            <InfiniteScroll
                dataLength={offset} //This is important field to render the next data
                next={fetchNextData}
                hasMore={offset < data.length}
                loader={<div>Loading...</div>}
                endMessage={
                    <p style={{ textAlign: 'center' }}>
                        <b>Yay! You have seen it all</b>
                    </p>
                }>
                <Cards details={games.slice(0, offset + limit)} />
            </InfiniteScroll>
        </div>
    )
}
