import games_data from './db/games_data.json';

interface QueryProps {
    order_by: string,
    score_lo: number,
    score_hi: number,
    subs: number[],
}

function sortByScoreHiToLo(games: (string | number | number[])[][]) {
    games.sort(function (a: any[], b: any[]) {
        return (a[2] > b[2]) ? -1 : 1;
    });
}

function sortAToZ(games: (string | number | number[])[][]) {
    games.sort(function (a: any[], b: any[]) {
        return (a[1].toLowerCase() > b[1].toLowerCase()) ? 1 : -1;
    });
}

function filterGames(games: (string | number | number[])[][], queryProps: QueryProps): (string | number | number[])[][] {
    return games.filter(function (game: any) {
        return typeof (game[2]) == 'number'
            && queryProps.score_lo <= game[2]
            && game[2] <= queryProps.score_hi
            && game[6].some((r: number) => queryProps.subs.indexOf(r) >= 0);
    }
    )
}

export function query(queryProps: QueryProps) {
    var filteredGames = filterGames(games_data.data, queryProps);
    queryProps.order_by == "0" ? sortAToZ(filteredGames) : sortByScoreHiToLo(filteredGames);
    return filteredGames;
}

