import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import './SubscriptionFilter.css';

const subscriptions = ['PS Now', 'Xbox Game Pass', 'Xbox Game Pass PC'];

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        typography: {
            padding: theme.spacing(2),

        },
    }),
);

const useSubscriptionStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            //   justifyContent: 'center',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
    }),
);

export function SubscriptionFilter(props: any): JSX.Element {
    // ======== This is boilerplate code for PopOver =======
    const classes = useStyles();
    const subscriptionClasses = useSubscriptionStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [needsRender, setNeedsRender] = useState(true);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChipClick = (e: any, subscriptionName: string) => {
        props.subscriptionChangeHandler(subscriptionName);
        let elem: any = e.target;
        if (elem.classList.contains('MuiChip-label')) {
            elem = elem.parentElement;
        }
        elem.classList.contains('chip-selected') ? elem.classList.remove('chip-selected') : elem.classList.add('chip-selected');
    }

    const handleResetClick = () => {
        for (const key of Object.keys(props.subscriptions)) {
            props.subscriptions[key].checked= true;
        }
        setNeedsRender(!needsRender);
        props.subscriptionChangeHandler(null);
    }

    const open = Boolean(anchorEl);
    const id = open ? "subscription-filter" : undefined;

    var subscriptionRows = [];
    for (const [key, value] of Object.entries(props.subscriptions)) {
        let subscriptionName: string = key;
        let className = (value as any).checked ? 'chip-selected' : '';
        subscriptionRows.push(
            <Chip className={className} label={subscriptionName} onClick={(event) => handleChipClick(event, subscriptionName)} />
        );
    }

    let buttonClass: string = '';
    for (const val of Object.values(props.subscriptions)) {
        if (!(val as any).checked) {
            buttonClass = "is-primary-button";
            break;
        }
    }

    return (
        <div style={{ marginLeft: "5px", marginTop: "2px", display: "inline-block" }}>
            <button className={buttonClass} type="button" onClick={handleClick}>Subscription</button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Typography className={classes.typography} style={{ height: "max(100%, 400px)", width: "335px", textAlign: "left", fontSize: "12px" }}>
                <button type="button" className="is-error-button" style={{position: "absolute", top: 5, right: 5}} onClick={handleResetClick}>Reset</button>
                    <div className="pure-g" style={{ paddingBottom: 5 }}>
                        <div className="pure-u-1-2" style={{ textAlign: "left" }}><h3>Subscription</h3></div>
                    </div>
                    <div className={subscriptionClasses.root}>
                        {subscriptionRows.map((item, idx) => (
                            <div key={subscriptions[idx]}>{item}</div>
                        ))}
                    </div>
                </Typography>
            </Popover>
        </div>
    );
}
